@font-face {
  font-family: "pub";
  src: url(fonts/8bitlim.ttf);
}

@font-face {
  font-family: "journal";
  src: url(fonts/screen.ttf);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/////

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: "journal", monospace;
}

.intro-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 600px;
  padding: 10px;
  padding-bottom: 50px;
  border-radius: 10px;
  background-color: #5a1d28;
  text-align: center;
  color: white;
  padding: 50px 0px;
}

.intro-title {
  font-size: 40px;
}

.intro-subtitle {
  margin-top: 6px;
  font-size: 15px;
}

.intro-book {
  margin-top: 30px;
  max-width: 30%;
  height: auto;
}

.intro-description {
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 18px;
}

@media only screen and (max-width: 600px) {
  .intro-box {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 0px;
    padding: 20px 0;
  }

  .intro-description {
    margin-bottom: 2%;
  }

  .intro-book {
    margin-top: 15px;
  }
}

.volume-selector {
  width: 70%;
  margin: 0 auto;
  /* display: flex;
  flex-direction: row; */
}

.volume-selector--item {
  width: 33.33%;
  display: inline-block;
  margin-bottom: 5px;
}

.volume-selector--item-inner {
  display: inline-block;
  background-color: transparent;
  font-size: 20px;
  padding: 3px 15px;
  border-radius: 30px;
  cursor: pointer;
}

.volume-selector--item-inner.active {
  background-color: red;
}

.volume-selector--breakdown {
  font-size: 18px;
  margin: 12px 5px 35px 5px;
}

@media only screen and (max-width: 400px) {
  .volume-selector--item {
    width: 50%;
  }
  .volume-selector--item-inner {
    width: 100%;
    padding: 1px 1px;
  }
  .intro-book {
    width: 100%;
    height: auto;
  }
  .volume-selector--breakdown {
    margin: 0px 5px 20px 5px;
  }
}

@media only screen and (max-width: 250px) {
  .volume-selector--item {
    width: 100%;
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px 20px;
  background: lightblue;
  background-image: url(images/button-center.png);
  background-repeat: repeat-x;
  background-size: 100% 100%;
  position: relative;
  margin: 0 6px;
}

.button-container:active .button {
  transform: scale(0.95);
}

.button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  width: 6px;
  height: 100%;
  background-image: url(images/button-left.png);
  background-size: 100% 100%;
}

.button:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  width: 6px;
  height: 100%;
  background-image: url(images/button-right.png);
  background-size: 100% 100%;
}

.button.red {
  background-image: url(images/button-center-red.png);
}

.button.red:before {
  background-image: url(images/button-left-red.png);
}

.button.red:after {
  background-image: url(images/button-right-red.png);
}

.button.green {
  background-image: url(images/button-center-green.png);
}

.button.green:before {
  background-image: url(images/button-left-green.png);
}

.button.green:after {
  background-image: url(images/button-right-green.png);
}

.button button {
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: #525252;
  text-shadow: 0 0.6px 0 #545454, 0 2px 0 #d6d6d6;
  font-family: "journal", monospace;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.button.red button {
  color: #4d3434;
  text-shadow: 0 0.6px 0 #4d3434, 0 2px 0 #d29c9c;
}

.button.green button {
  color: #364d34;
  text-shadow: 0 0.6px 0 #364d34, 0 2px 0 #a2d29c;
}

.button--overlay {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: skewX(20deg);
  opacity: 0;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  pointer-events: none;
}

.button:hover .button--overlay {
  animation: shine 0.4s ease-in-out;
}

@keyframes shine {
  0% {
    opacity: 0;
    left: 0;
  }

  15% {
    opacity: 1;
  }

  25% {
    opacity: 1;
    width: 5px;
  }

  50% {
    width: 20px;
  }

  75% {
    opacity: 1;
    width: 5px;
  }

  90% {
    opacity: 0;
  }

  100% {
    left: 100%;
    opacity: 0;
  }
}

.article-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 600px;
  padding: 10px;
  text-align: center;
}

.article-number {
  font-size: 19px;
}

.article-body {
  height: 89vh;
  background-color: white;
  padding: 25px;
}

.article-title {
  font-size: 27px;
  margin-bottom: 20px;
}

.article-hr {
  width: 70%;
  background-color: #979797;
}

.option-button-container {
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: 400px) {
  .article-title {
    font-size: 100vw;
  }
}

@media only screen and (max-width: 590px) {
  .article-container {
    width: 100%;
    overflow-y: scroll;
    padding: 0px;
    margin-bottom: 0px;
  }
  .article-body {
    width: 100%;
    padding: 15px;
  }
  .article-title {
    font-size: 4.5vw;
  }
}

@media only screen and (max-width: 360px) {
  .article-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 210px) {
  .article-title {
    font-size: 7vw;
  }
}

.volume-score {
  font-size: 50px;
}

.nextVol-description-container {
  margin: 20px;
}

.nextVol-description {
  font-size: 17px;
}

.article-answer-container {
  margin: 12px;
  margin-top: 0px;
}

.contribute-button {
  float: right;
}

.graduate-image {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}
